const _ = require('underscore');

function compileReports(reports) {
    let mergedCodes = {}

    reports.forEach((report) => {
        const { code_ids, data } = report;
        const { codes } = data.report;

        if ( !code_ids || code_ids.length === 0 ) {
            mergedCodes = {
                ...mergedCodes,
                ...codes
            }
        } else {
            code_ids.forEach(codeId => {
                const codeIdStr = codeId.toString();
                if (codes[codeIdStr]) {
                    mergedCodes[codeIdStr] = codes[codeIdStr];
                }
            });    
        }
    });

    const firstReport = reports[0]?.data;

    return {
        project_id: firstReport?.project_id,
        report: {
            codes: mergedCodes,
        }
    }
  }

export default compileReports; 