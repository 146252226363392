// app/javascript/projects/components/AnalysisExportModal.jsx

import React from 'react';
import ExportActions from 'actions/ExportActions'
import ModalComponent from '../modal/ModalComponent'
import {Modal, Button} from 'react-bootstrap'
import CodeStore from 'stores/CodeStore'

const MARGIN = 20;


class AnalysisExportModal extends React.Component {
  constructor () {
    super();
    this.exportSnippets = this.exportSnippets.bind(this);
    this.exportCodings = this.exportCodings.bind(this);
    this.exportCodesVCodes = this.exportCodesVCodes.bind(this);
    this.exportCodesVTranscripts = this.exportCodesVTranscripts.bind(this);
    this.exportCodesVDescriptor = this.exportCodesVDescriptor.bind(this);

    this.state = {
      loading: false
    }
  }

  exportSnippets() {
    const projectId = this.props.projectId;
    if ( !projectId) return null;
    ExportActions.exportSnippets(projectId);
  }

  exportCodings() {
    const projectId = this.props.projectId;
    if ( !projectId) return null;
    ExportActions.exportCodings(projectId);
  }

  exportCodesVCodes() {
    const projectId = this.props.projectId;
    if ( !projectId) return null;
    this.setState({loading: true});
    ExportActions.exportCodesVCodesMatrix(projectId).then(()=>{
      this.setState({loading: false});
    }).catch(()=>{
      this.setState({loading: false});
    })
  }

  exportCodesVTranscripts() {
    const projectId = this.props.projectId;
    if ( !projectId) return null;
    ExportActions.exportCodesVTranscriptsMatrix(projectId);
  }

  exportCodesVDescriptor() {
    const projectId = this.props.projectId;
    if ( !projectId) return null;
    ExportActions.exportCodesVDescriptorMatrix(projectId);
  }


  render () {
    return (
      <div>
      <Modal show={this.props.show} onHide={this.props.onHide}>
          <Modal.Body>
            <ModalComponent indent header margin={MARGIN}>
              <h3>Exports</h3>
            </ModalComponent>

            <ModalComponent indent margin={MARGIN}>
              <Button disabled={this.state.loading} bsStyle='link' style={{padding: 0}} onClick={this.exportSnippets}>
                <strong>Export Snippets as CSV</strong>
              </Button>
              <p>
                See a spreadsheet of snippets with their transcripts, codes, descriptors, and memos.
              </p>
              <p>
                To export a segment of data, use filters on Snippet page.
              </p>
            </ModalComponent>

            <ModalComponent indent margin={MARGIN}>
              <Button disabled={this.state.loading} bsStyle='link' style={{padding: 0}} onClick={this.exportCodesVCodes}>
                <strong>Export Code x Code Matrix CSV</strong>
              </Button>
              <p>
                See how codes co-occur and overlap
              </p>
            </ModalComponent>

            <ModalComponent indent margin={MARGIN}>
              <Button disabled={this.state.loading} bsStyle='link' style={{padding: 0}} onClick={this.exportCodesVTranscripts}>
                <strong>Export Code x Transcript Matrix CSV</strong>
              </Button>
              <p>
                See frequency of codes in each transcript
              </p>
            </ModalComponent>

            <ModalComponent footer indent margin={MARGIN}>
              <Button disabled={this.state.loading} bsStyle='link' style={{padding: 0}} onClick={this.exportCodesVDescriptor}>
                <strong>Export Code x Descriptor Matrix CSV</strong>
              </Button>
              <p>
                See how participant information correlates with codes
              </p>
            </ModalComponent>

            {false /* we may make this an activity feed export */ && 
              <ModalComponent indent footer margin={MARGIN}>
                <Button disabled={this.state.loading} bsStyle='link' style={{padding: 0}} onClick={this.exportCodings}>
                  <strong>Export Long-Form CSV</strong>
                </Button>
                <p>
                  Export your data to a CSV file where each snippet, code, and user combination is represented in a separate row.
                </p>
                <p>
                  This export is ideal for data analysis tools such as R, SPSS, and Excel PivotTables
                </p>
              </ModalComponent>
            }


          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AnalysisExportModal;
