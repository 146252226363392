const _ = require('underscore')

const _getArrayString = (key, value) => `${key}[]=${value}`;

const _getCodeString = (code_ids) => (code_ids || []).map(
  (code_id)=>_getArrayString('code_ids', code_id)
);

const _getTranscriptString = (transcript_ids) => (transcript_ids || []).map(
  (transcript_id)=>_getArrayString('transcript_ids', transcript_id)
);

const _getString = (key, value) => value ? [`${key}=${value}`] : [];
const _getOffsetString = (offset) => _getString('offset', offset)
const _getLimitString = (limit) => _getString('limit', limit)
const _getOpString = (op) => _getString('op', op);
const _getSortString = (sort) => {
  if ( sort === 'memoed' ) return ['sort=memoed'];
  return [];
}

const _getChoiceString = (choice_ids) =>
  _.flatten(
    _.pairs(choice_ids).map((array)=>
      array[1].map((choice)=>
        `choice_ids[${array[0]}][]=${choice}`
      )
    )
  )

const _getSourceString = (source) => _getString('source', source);

const getUrlParams = (code_ids, choice_ids, transcript_ids, limit, offset, op, sort, source) =>
  _getCodeString(code_ids)
  .concat(_getChoiceString(choice_ids))
  .concat(_getTranscriptString(transcript_ids))
  .concat(_getOffsetString(offset))
  .concat(_getLimitString(limit))
  .concat(_getOpString(op))
  .concat(_getSortString(sort))
  .concat(_getSourceString(source))
  .join('&');

export default getUrlParams;