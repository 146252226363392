import API from './API'

var ReportAPI = {
  // load single proje
  getTranscriptCodesReport: function(project_id) {
    return API.get(`/api/projects/${project_id}/transcript_codes_report`);
  },

  getCodesVCodesReport: function(project_id, code_ids, op='or') {
    const params = !!code_ids && code_ids.length > 0 ? {code_ids, op} : {};

    return API.post(`/api/projects/${project_id}/codes_codes_report`, params);
  },

  getDescriptorVCodesReport: function(project_id) {
    return API.get(`/api/projects/${project_id}/descriptor_codes_report`);
  },

  getCodeReport: function(code_id) {
    return API.get(`/api/codes/${code_id}/code_report`);
  }
};

export default ReportAPI
